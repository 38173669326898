import { createSelector } from '@ngrx/store';
import { Amount, BaseRequest, Cart, CartItem, Customer } from 'src/app/core/api/api.models';
import { selectEnvironmentInfo } from 'src/app/core/environment/environment.selectors';
import { selectCartId, selectLineItemsAndFees, selectParentConfigAmount, selectUserInfo } from 'src/app/core/parent-config/parent-config.selectors';
import { selectDeliveryAmount } from 'src/app/feature/delivery/delivery.selectors';
import { selectCartAddonTotal } from 'src/app/feature/extras/extras.selectors';

/**
 * Selects the amount from the parent config and cart addon total.
 */
const selectAmount = createSelector(
  selectParentConfigAmount,
  selectCartAddonTotal,
  selectDeliveryAmount,
  (cartTotal, cartAddOnTotal, deliveryTotal): Amount => ({
    cartTotal,
    extrasTotal: cartAddOnTotal + deliveryTotal,
  })
);

/**
 * Selects the customer information from the parent configuration.
 */
const selectCustomer = createSelector(selectUserInfo, (user = {}): Customer => {
  return {
    id: user.customerId ?? '',
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    emailAddress: user.email ?? '',
    phoneNumber: user.phone ?? '',
  };
});

/**
 * Selects the cart from the state.
 */
const selectCart = createSelector(selectLineItemsAndFees, selectCartId, ({ cartItems, fees }, cartId): Cart => {
  return {
    cartId,
    cartItems: cartItems as CartItem[],
    fees,
  };
});

/**
 * Returns an API BaseRequest object based on the provided selectors.
 */
export const selectBaseRequest = createSelector(
  selectAmount,
  selectCustomer,
  selectCart,
  selectEnvironmentInfo,
  (amount, customer, cart, environmentInfo): BaseRequest => {
    return {
      recurringType: 'none',
      amount,
      customer,
      cart,
      environmentInfo,
      authorizationExpiry: 2880,
      paymentIndex: 1,
    };
  }
);
