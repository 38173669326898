import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentEffects } from './payment.effects';
import { paymentReducer } from './payment.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('payment', paymentReducer), EffectsModule.forFeature([PaymentEffects])],
})
export class PaymentModule {}
