import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseByModule, responseRequestType } from 'src/app/core/api/api.utilities';
import { PaymentAuthResponse } from 'src/app/core/api/responses/payment-auth';
import { PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { getErrorLocaleFromResponse } from 'src/app/core/notification/notification.utils';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { sendPaymentAuthorized, sendPaymentFailed } from '../billing/billing.actions';
import { selectPaymentCompleteUserDetails } from '../billing/billing.selectors';
import { formatSingleAuthPayment, mapTescoAuthResponse } from '../billing/billing.utils';
import { authoriseTescoPaymentAction } from './payment.actions';
import { selectPaymentAuthRequest } from './payment.selectors';

@Injectable()
export class PaymentEffects {
  /**
   * PaymentEffects constructor
   * @param actions$ Our action stream
   * @param store Our store
   * @param localeService LocaleService
   */
  constructor(private actions$: Actions, private store: Store<AppState>, private localeService: LocaleService) {}

  handleTescoPaymentAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authoriseTescoPaymentAction),
      withLatestFrom(this.store.select(selectPaymentAuthRequest)),
      mergeMap(([action, authReq]) => [
        showPageSpinner({ initiator: 'pay with tesco voucher' }),
        setAuthorizingAction({ authorizing: true }),
        postApiRequest({
          requestType: ApiRequestType.PAYMENT_AUTH,
          body: authReq,
          params: { module: PaymentType.TESCO_VOUCHER },
        }),
      ])
    )
  );

  handleTescoPaymentResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.PAYMENT_AUTH)),
      filter(responseByModule(PaymentType.TESCO_VOUCHER)),
      withLatestFrom(this.store.select(selectPaymentCompleteUserDetails)),
      switchMap(([{ isOk, response }, user]) => {
        if (isOk) {
          // handle authorization response
          const { payments }: PaymentAuthResponse = response;
          const [{ billing, ...tescoPayment }] = payments.map(mapTescoAuthResponse);
          const authorization = formatSingleAuthPayment(tescoPayment, user);

          return [setAuthorizingAction({ authorizing: false }), sendPaymentAuthorized({ payload: authorization })];
        } else {
          return [
            hidePageSpinner({ initiator: 'pay with tesco voucher' }),
            setAuthorizingAction({ authorizing: false }),
            showNotificationAction({
              buttonLabel: 'close',
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'payment auth failure',
              message: getErrorLocaleFromResponse(this.localeService, apiResponse),
            }),
            sendPaymentFailed({ payload: { paymentType: PaymentType.TESCO_VOUCHER } }),
          ];
        }
      })
    )
  );
}
