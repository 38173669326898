import { createSelector } from '@ngrx/store';
import { selectLanguage } from 'src/app/core/client-configuration/client-configuration.selectors';
import { selectDeviceInfo, selectIpAddress } from 'src/app/core/environment/environment.selectors';
import { selectLineItemsAndFees, selectUserInfo } from 'src/app/core/parent-config/parent-config.selectors';
import { selectPaymentAmount } from 'src/app/shared/selectors/configuration.selectors';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { selectDonation, selectTicketProtection } from '../../extras/extras.selectors';
import { selectBillingDemographics } from '../billing.selectors';

export const selectAltPayDemographics = createSelector(selectBillingDemographics, selectUserInfo, (billing, user): Partial<DemographicsFormData> => {
  return {
    firstName: returnOrDefaultString(billing?.firstName, user?.firstName),
    middleInitial: returnOrDefaultString(billing?.middleInitial, user?.middleInitial),
    lastName: returnOrDefaultString(billing?.lastName, user?.lastName),
    address1: returnOrDefaultString(billing?.address1, user?.address1),
    address2: returnOrDefaultString(billing?.address2, user?.address2),
    city: returnOrDefaultString(billing?.city, user?.city),
    country: returnOrDefaultString(billing?.country, user?.country),
    state: returnOrDefaultString(billing?.state, user?.stateProvince),
    zip: returnOrDefaultString(billing?.zip, user?.zipPostal),
    email: returnOrDefaultString(billing?.email, user?.email),
    phone: returnOrDefaultString(billing?.phone, user?.phone),
    telCode: returnOrDefaultString(billing?.telCode, user?.phoneCountryCode),
    regionCode: returnOrDefaultString(billing?.telCode, user?.phoneRegionCode),
  };
});

export const selectInitAltPayRequestInfo = createSelector(
  selectLineItemsAndFees,
  selectLanguage,
  selectTicketProtection,
  selectDonation,
  selectPaymentAmount,
  selectAltPayDemographics,
  selectDeviceInfo,
  selectIpAddress,
  (lineItemsAndFees, language, insuranceState, donationState, paymentAmount, demographics, device, ipAddress) => ({
    lineItemsAndFees,
    language,
    insuranceState,
    donationState,
    paymentAmount,
    demographics,
    device,
    ipAddress,
  })
);
