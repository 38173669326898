import { PaymentMethod } from 'src/app/feature/payment/payment.state';
import { PaymentMethodCode, PaymentProviderType } from '../../payment-configuration/payment-configuration.model';
import { BaseRequest, BaseResponse } from '../api.models';

export const PAYMENT_AUTH_ENDPOINT = 'payment-auth';

export interface PaymentAuthRequest extends BaseRequest {
  payments: PaymentMethod[];
}

export interface PaymentAuthResponse extends BaseResponse {
  payments: PaymentResponse[];
}

export interface PaymentResponse extends BaseResponse {
  provider: PaymentProviderType;
  paymentReference: string;
  authId: string;
  paymentMethodCode: PaymentMethodCode;
  authTimestamp: string;
  amount: number;
  merchantId: number;
}
