import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { ComplementaryPaymentMethod, Payment, PaymentMethod } from '../../payment/payment.state';

/**
 * Checks if the given payment is a zero value payment.
 *
 * A zero value payment is defined as a payment with the provider type
 * `PaymentProviderType.Internal` and the payment method code
 * `PaymentMethodCode.COMPLEMENTARY`.
 *
 * @param payment - The payment method to check.
 * @returns True if the payment is a zero value payment, otherwise false.
 */
export function isZeroValuePayment(payment: PaymentMethod): payment is Payment<PaymentProviderType.Internal, ComplementaryPaymentMethod> {
  return payment.provider === PaymentProviderType.Internal && payment.paymentMethod.paymentMethodCode === PaymentMethodCode.COMPLEMENTARY;
}

/**
 * Determines if the provided payment methods array contains exactly one payment method
 * and if that payment method is a zero-value payment.
 *
 * @param payments - An array of payment methods to be checked.
 * @returns `true` if there is exactly one payment method and it is a zero-value payment, otherwise `false`.
 */
export function zeroValuePaymentAuthGuard(payments: PaymentMethod[]): boolean {
  return payments.length === 1 && isZeroValuePayment(payments[0]);
}
