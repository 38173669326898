import { AbstractControl } from '@angular/forms';
import { ZIP_CODE_BY_COUNTRY_REGEX } from './zip-code-by-country';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';

const countryIDs = Object.keys(ZIP_CODE_BY_COUNTRY_REGEX);

export const UNKNOWN_COUNTRY_CODE = 'ZZ';
export const COUNTRIES_REQUIRE_ZIP = ['AU', 'CA', 'GB', 'NZ', 'US'];

interface RegexOverride {
  [key: string]: string;
}

export const getRegExp = (str: string = ''): RegExp => new RegExp(`^${str.replace(/\\\\d/g, 'd')}$`, 'i');

export const getZipRegex = (country, regexOverride = ''): RegExp => getRegExp(returnOrDefaultString(regexOverride, ZIP_CODE_BY_COUNTRY_REGEX[country]));

export const isValidZip = (zipValue: string, regexOverride: RegexOverride = {}): boolean => {
  const hasOverride = validObject(regexOverride);
  return countryIDs.some((country: string) => getZipRegex(country, hasOverride && regexOverride[country]).test(zipValue));
};

export const isValidZipForCountry = (zipValue: string, country, regexOverride: RegexOverride = null): boolean => {
  return getZipRegex(country, returnOrDefaultString(validObject(regexOverride) && regexOverride[country])).test(zipValue);
};

export const validateZipField = (control: AbstractControl, requiredCountry: string = '', regexOverride: RegexOverride = null): void => {
  const required = COUNTRIES_REQUIRE_ZIP.includes(requiredCountry);
  const zipValue = returnOrDefaultString(control?.value).trim().toUpperCase();
  const hasValue = validString(zipValue);

  // If no value and not required, set no errors
  if (!hasValue && !required) {
    control.setErrors(null);
    return;
  }

  // If no value and required, set required error
  if (!hasValue && required) {
    control.setErrors({ required: true });
    return;
  }

  // If has no specfic country code, validate against all countries
  if (!validString(requiredCountry) || requiredCountry === UNKNOWN_COUNTRY_CODE) {
    const isValid = isValidZip(zipValue, regexOverride);
    control.setErrors(isValid ? null : { zipCode: true });
    return;
  }

  // Validate against specific country
  if (validString(requiredCountry)) {
    const isValid = isValidZipForCountry(zipValue, requiredCountry, regexOverride);
    control.setErrors(isValid ? null : { match: requiredCountry, zipCode: true });
  }
};
