import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentAuthRequest } from 'src/app/core/api/responses/payment-auth';
import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectBaseRequest } from 'src/app/shared/selectors/api.selectors';
import { Payment, PaymentMethod, PaymentState, TescoPaymentMethod } from './payment.state';

/**
 * Selects the entire payment state
 * @param state The app state
 * @returns The payment state
 */
export const selectPaymentState = createFeatureSelector<PaymentState>('payment');

/**
 * Selects the Tesco Vouchers entered
 * @param state The app state
 * @returns List of tesco vouchers
 */
export const selectTescoVouchers = createSelector(selectPaymentState, ({ payments }: PaymentState) => {
  return payments.filter(
    (p): p is Payment<PaymentProviderType.Tesco, TescoPaymentMethod> =>
      p.provider === PaymentProviderType.Tesco && p.paymentMethod.paymentMethodCode === PaymentMethodCode.TESCO
  );
});

/**
 * Selects the sum of Tesco Vouchers authorized
 * @param state The app state
 * @returns voucher sum
 */
export const selectTescoVoucherBalance = createSelector(selectTescoVouchers, (vouchers): number => {
  return vouchers.reduce((acc, voucher) => acc + voucher.amount, 0);
});

/**
 * Selects the sum of payments authorized
 * @param state The app state
 * @returns payment sum
 */
export const selectPaymentBalance = createSelector(selectPaymentState, ({ payments }: PaymentState): number => {
  return payments.reduce((acc, payment) => acc + payment.amount, 0);
});

/**
 * Selects the authorized payments
 * @param state The app state
 * @returns payment sum
 */
export const selectPaymentMethods = createSelector(selectPaymentState, (state: PaymentState): PaymentMethod[] => state.payments);

/**
 * Selects a preformatted authorization request
 */
export const selectPaymentAuthRequest = createSelector(
  selectPaymentMethods,
  selectBaseRequest,
  selectPaymentBalance,
  (payments, baseRequest, balance): PaymentAuthRequest => {
    // ************ REMOVE ************ //
    // remove after all payment methods are implemented to use consolidated API
    baseRequest.amount.cartTotal = balance;
    // ************ REMOVE ************ //

    return {
      ...baseRequest,
      payments,
    };
  }
);
