import { Inject, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { select, Store } from '@ngrx/store';
import { timeout } from 'guest-app-ui';
import { get } from 'lodash-es';
import { catchError, map, Observable, Subject, switchMap, take } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { RecaptchaV3ConfigState } from 'src/app/core/application-config/application-config.state';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { APP_CONFIG_RECAPTCHAV3 } from 'src/app/shared/enums/application-config.enum';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { validString } from 'src/app/shared/utilities/types.utils';

export enum RecaptchaAction {
  VERIFY_3DS = '3ds',
  AUTHORIZE = 'authorize',
}

export interface RecaptchaConfiguration {
  enabled: boolean;
  key: string;
  enterprise?: boolean;
}

export const DEFAULT_RECAPTCHAV3_DOMAIN_URL = 'https://www.google.com';
export const GLOBAL_RECAPTCHAV3_DOMAIN_URL = 'https://www.recaptcha.net';
export const RECAPTCHAV3_TOKEN_MIN_LENGTH = 500;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private config?: RecaptchaConfiguration;
  /**
   * Indicates whether recaptcha has been loaded
   */
  recaptchaAssetsLoaded = false;

  constructor(@Inject(WINDOW) private window: Window, private store: Store<AppState>, private locales: LocaleService) {
    store
      .pipe(
        select(selectAppConfig([APP_CONFIG_RECAPTCHAV3])),
        map((configs) => configs[APP_CONFIG_RECAPTCHAV3] ?? null)
      )
      .subscribe((recaptchav3) => (this.config = recaptchav3));
  }

  get enabled(): boolean {
    return this.config?.enabled === true && validString(this.siteKey);
  }

  get siteKey(): string {
    return this.config?.key ?? '';
  }

  /**
   * Executes a recaptcha and returns the token from Google
   * @param action The action being done
   * @returns The token received from Google
   */
  execute(action: RecaptchaAction): Observable<string | null> {
    const resolver = new Subject<string | null>();
    const { window } = this;

    if (!this.enabled || !get(window, 'grecaptcha')) {
      timeout(() => {
        resolver.next(null);
        resolver.complete();
      });
    } else {
      const recaptcha: ReCaptchaV2.ReCaptcha = get(window, `grecaptcha${this.config.enterprise ? '.enterprise' : ''}`);
      recaptcha.ready(() => {
        recaptcha.execute(this.siteKey, { action }).then((token) => {
          if (token.length < RECAPTCHAV3_TOKEN_MIN_LENGTH) {
            Bugsnag.notify(`RecaptchaService`, (event) => {
              event.addMetadata('Recaptcha token', { token, reason: 'Token is less than 500 characters' });
            });
          }
          // Push it to the next tick because this may endup getting ran syncronously
          timeout(() => {
            resolver.next(token);
            resolver.complete();
          });
        });
      });
    }

    return resolver.asObservable();
  }

  /**
   * Load recaptcha script to DOM
   * @returns Observable of method's result
   */
  loadScript(): Observable<boolean> {
    return this.recaptchaAssetsLoaded || !this.enabled
      ? toObservable(true)
      : this.store.pipe(
          select(selectAppConfig([APP_CONFIG_RECAPTCHAV3])),
          take(1),
          map((configs) => (configs[APP_CONFIG_RECAPTCHAV3] as RecaptchaV3ConfigState)?.useGlobalDomain),
          switchMap((useGlobalDomain) => this.addScript(!!useGlobalDomain))
        );
  }

  /**
   * Add recaptcha script to DOM
   * @param useGlobalDomain Use recaptcha's global domain url
   * @returns Observable of method's result
   */
  addScript(useGlobalDomain: boolean): Observable<boolean> {
    const url = !!useGlobalDomain ? GLOBAL_RECAPTCHAV3_DOMAIN_URL : DEFAULT_RECAPTCHAV3_DOMAIN_URL;
    return addScript(`${url}/recaptcha/api.js?render=${this.siteKey}`, true, {
      id: 'apay-google-recaptcha-v3-script',
    }).pipe(
      switchMap(() => {
        this.recaptchaAssetsLoaded = true;
        return toObservable(true);
      }),
      catchError((_) => {
        Bugsnag.notify(`RecaptchaResolve`, (event) => {
          event.addMetadata('Recaptcha', { recaptcha: 'failed to load' });
        });
        this.store.dispatch(
          showNotificationAction({
            initiator: 'Recaptcha loading error',
            dialogType: NotificationDialogType.GENERAL,
            buttonLabel: this.locales.get('common.close'),
            message: this.locales.get('recaptcha.loadingerror'),
          })
        );
        return toObservable(true);
      })
    );
  }
}
