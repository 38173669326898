import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecaptchaService } from './recaptcha.service';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaResolve implements Resolve<boolean> {
  /**
   * Constructor
   * @param recaptcha Recaptcha service
   */
  constructor(private recaptcha: RecaptchaService) {}

  /**
   * This function is always called when this is used as a route resolve. Makes sure we have valid QR Code data
   * prior to the page rendering.
   * @param route The route snapshot.
   * @param state The router state snapshot.
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.recaptcha.loadScript();
  }
}
