import { createAction, props } from '@ngrx/store';
import { PaymentMethod } from './payment.state';

export enum PaymentActions {
  addPaymentAction = '[Payment Component] Add Payment',
  authorisePaymentAction = '[Payment Component] Authorise Payment',
  authoriseTescoPaymentAction = '[Select Component] Authorise Tesco Payment',
}

export const addPaymentAction = createAction(PaymentActions.addPaymentAction, props<{ payment: PaymentMethod }>());
export const authorisePaymentAction = createAction(PaymentActions.authorisePaymentAction);
export const authoriseTescoPaymentAction = createAction(PaymentActions.authoriseTescoPaymentAction);
