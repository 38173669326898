import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

/**
 * A validator that requires the control to have a non-empty value.
 */
export function requiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;
    if (typeof control.value === 'string') {
      value = value.trim();
    }

    return Validators.required({ ...control, value: value } as AbstractControl);
  };
}
