import { Address } from 'src/app/core/api/api.models';
import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';

export type CCDPaymentMethods = Exclude<PaymentProviderType, PaymentProviderType.Tesco | PaymentProviderType.StoredValue | PaymentProviderType.Internal>;
export type PaymentMethod =
  | Payment<PaymentProviderType.Tesco, TescoPaymentMethod>
  | Payment<PaymentProviderType.StoredValue, SVCPaymentMethod>
  | Payment<PaymentProviderType.Internal, ComplementaryPaymentMethod>
  | Payment<CCDPaymentMethods, CreditCardPaymentMethod>;

export interface PaymentState {
  payments: PaymentMethod[];
}

export interface Payment<T extends PaymentProviderType, M> {
  amount: number;
  availableAmount?: number;
  merchantId: number;
  paymentMethod: M;
  provider: T;
  ignoreAvs?: boolean;
  ignoreCvv?: boolean;
  is3DSecure?: boolean;
}

export interface TescoPaymentMethod {
  voucherNumber: string;
  productCode: string;
  paymentMethodCode: PaymentMethodCode.TESCO;
}

export interface SVCPaymentMethod {
  cardNumber: string;
  pin?: string;
  paymentMethodCode: PaymentMethodCode.SVC;
  billingAddress?: Address;
}

export interface ComplementaryPaymentMethod {
  paymentMethodCode: PaymentMethodCode.COMPLEMENTARY;
  billingAddress: Address;
}

export interface CreditCardPaymentMethod {
  tokenPayload: string;
  productCode: string;
  paymentMethodCode: CCDPaymentMethods;
  billingAddress: Address;
  rawCardBrand?: string;
  saveCardToProfile?: boolean;
}

export const initialState: PaymentState = {
  payments: [],
};
