import { Params } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { PaymentComplete, PaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { selectEnvironment } from 'src/app/core/environment/environment.selectors';
import { selectParentConfig, selectParentConfigAmount, selectRecurring } from 'src/app/core/parent-config/parent-config.selectors';
import { selectConfiguredPayments, selectCreditCards, selectGiftCard } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { APP_CONFIG_SANDBOX_MODE } from 'src/app/shared/enums/application-config.enum';
import { selectRequireCVV } from 'src/app/shared/selectors/configuration.selectors';
import { formatDemographicsForPaymentComplete } from 'src/app/shared/utilities/demographics.utils';
import { toDecimal } from 'src/app/shared/utilities/number.utils';
import { selectDelivery, selectDeliveryAmount, selectPaymentCompleteDelivery } from '../delivery/delivery.selectors';
import { DeliveryState } from '../delivery/delivery.state';
import { CountriesProperties } from '../dynamic-form/utilities/countries-props.models';
import { selectCartAddonTotal, selectExtras, selectTicketInsuranceQuoteToken, selectTicketProtection } from '../extras/extras.selectors';
import { selectGiftCardState, selectPreAuthorizedGiftCards } from '../gift-card/gift-card.selectors';
import { GiftCard } from '../gift-card/gift-card.state';
import { BillingState } from './billing.state';
import { selectStoredWallet } from './stored-wallet/stored-wallet.selectors';

/**
 * Selects the main billing state
 * @param appState The global state
 */
export const selectBillingState = createFeatureSelector<BillingState>('billing');

/**
 * This is the sum of everything before any payments are processed.
 * Potential replacement for selectTotalAmount.
 */
export const selectCartTotal = createSelector(
  selectParentConfigAmount,
  selectDeliveryAmount,
  selectCartAddonTotal,
  (parentConfigAmount, deliveryAmount, cartAddonTotal): number => {
    return toDecimal(parentConfigAmount + deliveryAmount + cartAddonTotal, 2);
  }
);

/**
 * Selector to determine if the transaction is a zero-value transaction.
 * This selector checks if the cart total is zero and if the transaction is not recurring.
 */
export const selectIsZeroValueTransaction = createSelector(selectCartTotal, selectRecurring, (cartTotal, recurring): boolean => !recurring && cartTotal === 0);

/**
 * Payment amount with cart add-ons
 */
export const selectPaymentAmountWithExtras = createSelector(selectCartTotal, selectGiftCardState, selectExtras, (total, giftCardState, extrasState): number => {
  if (!giftCardState.isGiftCardOnlyPayment && giftCardState.totalBalance > 0) {
    return toDecimal(total - giftCardState.totalBalance, 2);
  }
  return total;
});

/**
 * Selects the main information about billing.
 */
export const selectBillingInformation = createSelector(
  selectRequireCVV,
  selectCreditCards,
  selectParentConfig,
  selectConfiguredPayments,
  selectEnvironment,
  selectStoredWallet,
  selectBillingState,
  selectTicketInsuranceQuoteToken,
  (requireCvv, creditCards, parentConfig, configuredPayments, environment, storedWallet, billingState, quoteToken) => {
    return {
      requireCvv,
      creditCards,
      parentConfig,
      configuredPayments,
      environment,
      storedWallet,
      billingState,
      quoteToken,
    };
  }
);

/**
 * Selects the state that contain billing demographics
 * @returns billing demographics
 */
export const selectBillingDemographics = createSelector(selectBillingState, (state: BillingState): Partial<DemographicsFormData> => state?.demographics);

/**
 * Selects the state that contain billing demographics for payment complete event
 * @returns billing demographics
 */
export const selectPaymentCompleteBillingDemographics = createSelector(
  selectBillingDemographics,
  (demographics: Partial<DemographicsFormData>): Partial<DemographicsFormData> => formatDemographicsForPaymentComplete(demographics)
);

/**
 * Selects the state that contains redirect payment query params
 * @returns redirect payment query params
 */
export const selectRedirectPaymentsQueryParams = createSelector(selectBillingState, (state: BillingState): Params => state.redirectPaymentsQueryParams);

/**
 * Selects the state that contains gift card payment availability status
 * @returns availability status for gift card
 */
export const selectGiftCardConfigurationStatus = createSelector(selectBillingState, (state: BillingState): boolean => state.giftcard);

/**
 * Select the billing details required to complete the payment
 * @returns billing and delivery details
 */
export const selectBillingDetailsForPayment = createSelector(
  selectBillingState,
  selectDelivery,
  (billingState, deliveryState): { billingState: BillingState; deliveryState: DeliveryState } => {
    return { billingState, deliveryState };
  }
);

/**
 * Select the billing and delivery details required to complete the payment
 * @returns billing and delivery details
 */
export const selectPaymentCompleteUserDetails = createSelector(
  selectPaymentCompleteBillingDemographics,
  selectPaymentCompleteDelivery,
  selectDelivery,
  (billing, deliveryAddress, { deliveryMethodsConfigured, collectAddress }): PaymentComplete['user'] => {
    const user: PaymentComplete['user'] = { billing };

    if (deliveryMethodsConfigured && collectAddress) {
      user.delivery = deliveryAddress;
    }

    return user;
  }
);

/**
 * Select ticketInsurance state and giftcard to render the Ewallets methods
 * @returns billing and delivery details
 */
export const selectShowEwalletPaymentMethods = createSelector(selectTicketProtection, selectGiftCardState, (ticketProtection, giftCardConfig): boolean => {
  return !(ticketProtection.isProtected && !ticketProtection.splitInsurance && giftCardConfig.totalBalance);
});

/**
 * Select the countries used billing details
 * @returns list of supported countries
 */
export const selectCountriesList = createSelector(selectBillingState, (state: BillingState): CountriesProperties[] => state?.formOptions?.countryCodes);

/**
 * Select Form Options used in Billing details
 * @param {string[]} names Properties to retrieve from Billing.formOptions
 * @returns Properties found
 */
export const selectFormOptions = <T extends string>(names: readonly T[]) => {
  return (state: AppState): Record<T, any> => {
    return names.reduce((options: Record<T, any>, name: string) => {
      options[name] = state.billing?.formOptions[name];
      return options;
    }, {} as Record<T, any>);
  };
};

/**
 * Select use sandbox flow
 */
export const selectUseSandbox = createSelector(selectEnvironment, selectAppConfig([APP_CONFIG_SANDBOX_MODE]), (environment, appConfigs): boolean => {
  return environment?.isProduction ? !!appConfigs[APP_CONFIG_SANDBOX_MODE] : true;
});

/**
 * Selects the preauthorized gift cards payment info
 * @returns gift cards
 */
export const selectPreAuthGiftCardPaymentDetails = createSelector(
  selectGiftCard,
  selectPreAuthorizedGiftCards,
  selectPaymentCompleteBillingDemographics,
  ({ paymentMerchantId, paymentMethodCode, paymentProviderName, rawCardBrand }, preAuthGiftCards: GiftCard[], demographics): PaymentInfo[] => {
    return preAuthGiftCards.map(({ authId, preAuthBalance, paymentReference, donation, insurance, amount }) => ({
      amount,
      method: PaymentType.GIFT_CARD,
      authId: paymentReference,
      legacy: {
        authId,
        paymentMerchantId,
        paymentMethodCode,
        paymentProviderName,
        rawCardBrand,
      },
      billing: demographics,
      ...(donation && { donation }),
      ...(insurance && { insurance }),
    }));
  }
);
