import { AlternatePaymentsModule } from './alternate-payments/alternate-payments.module';
import { ApplePayModule } from './applepay/applepay.module';
import { BillingEffects } from './billing.effects';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GooglePayModule } from './google-pay/google-pay.module';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { billingReducer } from './billing.reducer';
import { PayPalModule } from './paypal/paypal.module';
import { AmazonPayModule } from './amazon-pay/amazon-pay.module';
import { InternalPaymentModule } from './internal/internal.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('billing', billingReducer),
    EffectsModule.forFeature([BillingEffects]),
    AmazonPayModule,
    ApplePayModule,
    GooglePayModule,
    PayPalModule,
    AlternatePaymentsModule,
    InternalPaymentModule,
  ],
})
export class BillingModule {}
