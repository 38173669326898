export enum ApiRequestType {
  // Core
  GET_ENVIRONMENT = 'GetEnvironment',
  GET_APPLICATION_CONFIG = 'GetApplicationConfigs',
  GET_PAYMENT_CONFIGURATION = 'GetPaymentConfiguration',
  GET_APPLICATION_LOCALE = 'GetApplicationLocale',
  GET_ACCESSO_PAY_SESSION = 'GetAccessoPaySession',
  CREATE_ACCESSO_PAY_SESSION = 'CreateAcessoPaySession',
  ACCESSO_PAY_SESSIONS = 'accesso-pay-sessions',

  // Adyen
  ADYEN_ENROLLMENT_CHECK_3DS = 'AdyenEnrollmentCheck3DS',
  ADYEN_UPDATE_3DS_RESULT = 'AdyenUpdate3DSResult',

  // Cybersource
  INIT_CYBERSOURCE_FLEX_SESSION = 'init-cybersource-flex-session',
  CREATE_CARDINAL_JWT = 'createjwt',

  // FreedomPay
  INITIATE_FREEDOMPAY_SESSION = 'init-freedompay-hpc-session',

  // Wallet Payments
  INITIATE_GOOGLEPAY_TXN = 'InitiateGooglePayTransaction',
  INITIATE_APPLEPAY_SESSION = 'InitiateApplePaySession',
  EWALLET_AUTHORIZE = 'EWalletAuthorization',

  // Alternate Payments
  INITIATE_ALT_PAY = 'InitiateAltPay',
  UPDATE_ALT_PAY = 'UpdateAltPay',

  // Consolidated Endpoint
  PAYMENT_AUTH = 'payment-auth',

  // Stored Payments
  RETRIEVE_TOKENS = 'RetrieveTokens',
  DELETE_TOKEN = 'DeleteToken',
  PAY_WITH_TOKEN = 'PayWithToken',

  // 3D Secure
  ENROLLMENT_CHECK_3DS = 'enrollmentCheck3DS',
  VALIDATE_3DS = 'validate3DS',

  // Card Capture
  AUTHORIZE = 'Authorize',

  // Amazon Pay
  AMAZON_PAY_GENERATE_SESSION = 'GenerateAmazonPaySession',
  AMAZON_PAY_COMPLETE_SESSION = 'CompleteAmazonPaySession',

  // PayPal
  CREATE_PAYPAL_ORDER = 'CreatePayPalOrder',
  UPDATE_PAYPAL_STATUS = 'UpdatePayPalStatus',
  CREATE_PAYPAL_BILLING_AGREEMENT = 'CreatePayPalBillingAgreement',
  CREATE_PAYPAL_BILLING_AGREEMENT_ORDER = 'CreatePayPalBillingAgreementOrder',

  // Extras
  REGISTER_INSURANCE_QUOTE = 'RegisterInsuranceQuote',

  // Gift Card
  SVC_GET_BALANCE = 'SVCGetBalance',
  SVC_PRE_AUTH = 'SVCPreAuth',

  // Tesco Voucher
  TESCO_GET_BALANCE = 'voucher-get-balance',
}

export type StringOrNumber = string | number;

export type TrueOrFalse = 'true' | 'false';
