import { Params } from '@angular/router';
import { isObject } from 'lodash-es';
import { filterValidPaymentMethods } from 'src/app/shared/utilities/alternate-payment.utils';
import {
  filterAlternatePaymentMethods,
  filterForApplePayWalletPayment,
  filterForCreditCard,
  filterForGooglePayWalletPayment,
  filterForWalletPayments,
  filterValidCardBrands,
} from 'src/app/shared/utilities/credit-cards.utils';
import { validArray } from 'src/app/shared/utilities/types.utils';
import { PaymentMethod } from '../api/responses/get-payment-configuration';
import { PaymentType } from '../application-bridge/application-bridge.models';
import { DisablePayments } from '../parent-config/parent-config.state';
import { REDIRECT_PAYMENT_QUERY_PARAMS } from '../routing/routing.enums';
import {
  AlternatePayment,
  ConfiguredPayments,
  CreditCard,
  GiftCardPayment,
  PaymentMethodCode,
  PaymentProviderType,
  WalletPayment,
} from './payment-configuration.model';

/**
 * Build payment configuration model
 * @param response payment configuration response
 * @param disablePayments disable payments
 */
export function buildPaymentConfigurationModel(response: PaymentMethod[], disablePayments: Partial<DisablePayments>, recurring: boolean): ConfiguredPayments {
  const configuredPayments: ConfiguredPayments = {
    applePayCards: [],
    creditCards: [],
    googlePayCards: [],
    alternatePayments: [],
    giftCard: null,
  };

  if (!isObject(response)) {
    return configuredPayments;
  }

  const isApplePayDisabled = disablePayments?.paymentMethodName.includes(PaymentType.APPLE_PAY) || disablePayments?.alternatePayments;
  const isGooglePayDisabled = disablePayments?.paymentMethodName.includes(PaymentType.GOOGLE_PAY) || disablePayments?.alternatePayments;
  const isCreditCardDisabled = disablePayments?.paymentMethodName.includes(PaymentType.CREDIT_CARD) || false;
  const isPaypalDisabled = disablePayments?.paymentMethodName.includes(PaymentType.PAYPAL) || false;
  const paypalConfig = response.find((method) => method.paymentMethodName.toLowerCase() === PaymentType.PAYPAL) as AlternatePayment;
  const isGiftCardDisabled = recurring || disablePayments?.paymentMethodName.includes(PaymentType.GIFT_CARD) || disablePayments?.alternatePayments;
  const disabledCardBrands = disablePayments?.cardBrandName;
  const altPayments = disablePayments?.alternatePayments || recurring ? [] : buildAlternatePayments(response);

  configuredPayments.creditCards = isCreditCardDisabled ? [] : buildCreditCards(response).filter(filterValidCardBrands.bind(null, disabledCardBrands));
  configuredPayments.applePayCards = isApplePayDisabled
    ? []
    : buildWalletPayments(response, true, false).filter(filterValidCardBrands.bind(null, disabledCardBrands));
  configuredPayments.googlePayCards = isGooglePayDisabled
    ? []
    : buildWalletPayments(response, false, true).filter(filterValidCardBrands.bind(null, disabledCardBrands));
  configuredPayments.alternatePayments = validArray(disablePayments?.paymentMethodName)
    ? altPayments.filter(filterValidPaymentMethods.bind(null, disablePayments.paymentMethodName))
    : altPayments;
  if (recurring && !isPaypalDisabled && paypalConfig) {
    configuredPayments.alternatePayments.push(paypalConfig);
  }
  configuredPayments.giftCard = isGiftCardDisabled ? null : buildGiftCard(response);

  return configuredPayments;
}

/**
 * Update configured payments
 * @param configuredPayments merchant details
 * @param disablePayments disable payments
 */
export function updateMerchant(configuredPayments: ConfiguredPayments, disablePayments: Partial<DisablePayments>, recurring: boolean): ConfiguredPayments {
  const isApplePayDisabled = (disablePayments?.paymentMethodName.includes(PaymentType.APPLE_PAY) || disablePayments?.alternatePayments) ?? false;
  const isGooglePayDisabled = (disablePayments?.paymentMethodName.includes(PaymentType.GOOGLE_PAY) || disablePayments?.alternatePayments) ?? false;
  const isCreditCardDisabled = disablePayments?.paymentMethodName.includes(PaymentType.CREDIT_CARD) || false;
  const disabledCardBrands = disablePayments?.cardBrandName;
  const alternatePayments = disablePayments?.alternatePayments || recurring ? [] : configuredPayments.alternatePayments;
  const updatedConfiguredPayments = {
    ...configuredPayments,
    alternatePayments:
      validArray(disablePayments?.paymentMethodName) && alternatePayments.length
        ? alternatePayments.filter(filterValidPaymentMethods.bind(null, disablePayments.paymentMethodName))
        : alternatePayments,
    creditCards: isCreditCardDisabled ? [] : configuredPayments.creditCards?.filter(filterValidCardBrands.bind(null, disabledCardBrands)) ?? [],
    applePayCards: isApplePayDisabled ? [] : configuredPayments.applePayCards?.filter(filterValidCardBrands.bind(null, disabledCardBrands)) ?? [],
    googlePayCards: isGooglePayDisabled ? [] : configuredPayments.googlePayCards?.filter(filterValidCardBrands.bind(null, disabledCardBrands)) ?? [],
  };

  return updatedConfiguredPayments;
}

/**
 * Find a payment by a payment method code
 * @param payments The alternate payments
 * @param code The code to search for
 * @returns The alternate payment
 */
export function findByPaymentMethodCode(payments: AlternatePayment[], code: string): AlternatePayment {
  return payments.find((payment) => payment.paymentMethodCode === code);
}

/**
 * Sorts payment methods by display order
 * @param a payment method
 * @param b payment method
 */
export function sortByOrder(a: PaymentMethod, b: PaymentMethod): number {
  return a.displayOrder - b.displayOrder;
}

/**
 * Builds credit cards from payment methods
 * @param paymentMethods payment methods
 */
export function buildCreditCards(paymentMethods?: PaymentMethod[]): CreditCard[] {
  if (validArray(paymentMethods)) {
    return paymentMethods.filter(filterForCreditCard).sort(sortByOrder) as CreditCard[];
  }

  return [];
}

/**
 * Builds wallet payments from payment methods
 * @param paymentMethods payment methods
 * @param isApplePay is ApplePay
 * @param isGooglePay is GooglePay
 */
export function buildWalletPayments(paymentMethods?: PaymentMethod[], isApplePay = false, isGooglePay = false): WalletPayment[] {
  if (validArray(paymentMethods)) {
    let walletPaymentTypeFilter = (walletPayment: WalletPayment) => true;
    if (isApplePay) {
      walletPaymentTypeFilter = filterForApplePayWalletPayment;
    } else if (isGooglePay) {
      walletPaymentTypeFilter = filterForGooglePayWalletPayment;
    }
    return paymentMethods.filter(filterForWalletPayments).filter(walletPaymentTypeFilter).sort(sortByOrder) as WalletPayment[];
  }

  return [];
}

/**
 * Builds alternate payments from payment methods
 * @param paymentMethods payment methods
 */
export function buildAlternatePayments(paymentMethods?: PaymentMethod[]): AlternatePayment[] {
  if (validArray(paymentMethods)) {
    return paymentMethods.filter(filterAlternatePaymentMethods).sort(sortByOrder) as AlternatePayment[];
  }

  return [];
}

/**
 * Builds gift card payment for payment methods
 * @param paymentMethods payment methods
 * @returns gift card
 */
export function buildGiftCard(paymentMethods?: PaymentMethod[]): GiftCardPayment | null {
  if (validArray(paymentMethods)) {
    return (
      (paymentMethods.find((paymentMethod) => {
        return paymentMethod.paymentMethodCode === PaymentMethodCode.SVC && paymentMethod.paymentProviderName === PaymentProviderType.StoredValue;
      }) as GiftCardPayment) || null
    );
  }

  return null;
}

/**
 * Find out if there are any redirect payments query params
 */
export function hasRedirectPaymentsQueryParams(queryParams: Params): boolean {
  return Object.values(REDIRECT_PAYMENT_QUERY_PARAMS).some((key) => queryParams.hasOwnProperty(key));
}
