import { PaymentResponse } from 'src/app/core/api/responses/payment-auth';
import {
  AuthorizedPaymentInfo,
  PaymentComplete,
  PaymentInfo,
  PaymentType,
  SplitPaymentComplete,
} from 'src/app/core/application-bridge/application-bridge.models';
/**
 * Format payload for Payment Complete event with multiple payment methods
 * @param authorizedPayments list of authorized payments
 * @param user delivery details
 * @returns payment complete payload
 */
export const formatMultiAuthPayments = (authorizedPayments: PaymentInfo[], { delivery }: PaymentComplete['user']): SplitPaymentComplete => ({
  paymentInfo: [...authorizedPayments],
  ...(delivery && { delivery }),
});

/**
 * Format payload for Payment Complete event for single payment
 * @param authorizedPayment authorized payment
 * @param user billing and delivery
 * @returns payment complete payload
 */
export const formatSingleAuthPayment = (authorizedPayment: AuthorizedPaymentInfo, user: PaymentComplete['user']): PaymentComplete => ({
  ...authorizedPayment,
  user,
});

/**
 * Format Tesco authorization payload for Payment Complete event
 * @param authorizedPayment authorized payment response
 * @returns formatted payment info
 */
export const mapTescoAuthResponse = ({ amount, paymentReference, authId, ...response }: PaymentResponse): PaymentInfo => ({
  amount,
  method: PaymentType.TESCO_VOUCHER,
  authId: paymentReference,
  legacy: {
    authId,
    paymentMerchantId: response.merchantId,
    paymentMethodCode: response.paymentMethodCode,
    paymentProviderName: response.provider,
  },
  billing: {},
});
