import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { isZeroValuePayment, zeroValuePaymentAuthGuard } from './internal.utils';
import { authorisePaymentAction, PaymentActions } from '../../payment/payment.actions';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectPaymentMethods } from '../../payment/payment.selectors';
import { ApiService } from 'src/app/core/api/api.service';
import { handleZeroPaymentAuthError, handleZeroValuePaymentAuthResponse } from './internal.actions';
import { AppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { ApiStatus } from 'src/app/core/api/api.interface';
import { getErrorLocaleFromResponse } from 'src/app/core/notification/notification.utils';
import { PaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { sendPaymentAuthorized } from '../billing.actions';
import { selectPaymentMerchantId } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { ComplementaryPaymentMethod } from '../../payment/payment.state';
import { formatSingleAuthPayment } from '../billing.utils';

@Injectable()
export class InternalPaymentEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private localeService: LocaleService, private apiService: ApiService) {}

  zeroValuePaymentAdded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.addPaymentAction),
      filter(({ payment }) => isZeroValuePayment(payment)),
      map(() => authorisePaymentAction())
    )
  );

  zeroValuePaymentAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.authorisePaymentAction),
      withLatestFrom(this.store.select(selectPaymentMethods)),
      filter(([_, payments]) => zeroValuePaymentAuthGuard(payments)),
      switchMap(([_, payments]) => {
        this.store.dispatch(showPageSpinner({ initiator: 'zero value auth' }));
        return this.apiService.paymentAuth().pipe(
          map((res) => handleZeroValuePaymentAuthResponse({ res, paymentMethod: payments[0].paymentMethod as ComplementaryPaymentMethod })),
          catchError(() => of(handleZeroPaymentAuthError({})))
        );
      })
    )
  );

  handleZeroValuePaymentAuthResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleZeroValuePaymentAuthResponse),
      map(({ res, paymentMethod }) => {
        const { status, response } = res;
        if (status === ApiStatus.FAILED) {
          return handleZeroPaymentAuthError({ error: getErrorLocaleFromResponse(this.localeService, response) });
        }

        const responsePayload = response.payments[0];
        const { amount, authId, paymentReference, merchantId, paymentMethodCode, provider } = responsePayload;
        const payload: PaymentInfo = {
          amount: amount,
          billing: paymentMethod.billingAddress,
          method: PaymentType.COMPLEMENTARY,
          authId: authId,
          paymentReference: paymentReference,
          legacy: {
            authId: authId,
            paymentMerchantId: merchantId,
            paymentMethodCode: paymentMethodCode,
            paymentProviderName: provider,
          },
        };

        return sendPaymentAuthorized({ payload });
      })
    )
  );

  handleZeroPaymentAuthError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleZeroPaymentAuthError),
      mergeMap(({ error }) => {
        return [
          hidePageSpinner({ initiator: 'zero value auth failure' }),
          showNotificationAction({
            buttonLabel: this.localeService.get('common.close'),
            dialogType: NotificationDialogType.GENERAL,
            initiator: 'zero value auth failure',
            message: error || this.localeService.get('error.unexpected'),
          }),
        ];
      })
    )
  );
}
